module.exports = {
  title: `Ykss`,
  description: `성장하는 엔지니어가 되기 위한 기록`,
  image: `content/assets/felog.png`,
  author: `[Ykss]`,
  introduction: `고이게 두지 않고 흘려보내는 개발자가 되자.`,
  siteUrl: `https://ykss.netlify.app`, // Your blog site url
  social: {
    twitter: ``, // Your Twitter account
    github: `ykss`, // Your GitHub account
    medium: ``, // Your Medium account
    facebook: ``, // Your Facebook account
    linkedin: `kyeongsangyu`, // Your LinkedIn account,
    instagram: `kyeongsangyu`, // Your Instagram account
  },
  icon: `content/assets/felog.png`, // Add your favicon
  keywords: [`blog`],
  comment: {
    disqusShortName: '', // Your disqus-short-name. check disqus.com.
    utterances: 'ykss/ykss.netlify.com', // Your repository for archive comment
  },
  configs: {
    countOfInitialPost: 10, // Config your initial count of post
  },
  sponsor: {
    buyMeACoffeeId: '',
  },
  share: {
    facebookAppId: '', // Add facebookAppId for using facebook share feature v3.2
  },
  ga: 'UA-116559927-2', // Add your google analytics tranking ID,
  ad: 'pub-5924627079296495', // Add your google adsense publisherId `ca-pub-xxxxxxxxxx`
};
